/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Icon } from '@cbrebuild/blocks';
import formatPaymentDate from '../../utils/format-payment-date';
import tracker from '../../services/dashboard-tracking-service';
import traunchDetailsService from '../../services/traunch-details-service';
import installmentsService from '../../services/voucher/installments-service';
import dealsService from '../../services/deals-service';
import proxyService from '../../services/proxy-service';
import getCounterPartyLabel from '../../utils/get-counter-party-label';
import useLocalStorage from '../../hooks/use-local-storage';

import Widget from '../../nucleus/widget/widget';
import ExpandToggle from '../../nucleus/expand-toggle/expand-toggle';
import ButtonSegmentedGroup from '../../nucleus/button-segmented-group/button-segmented-group';
import StackedLabeledItem from '../../nucleus/stacked-labeled-item/stacked-labeled-item';
import Currency from '../../nucleus/formats/currency';
import DealLabel from '../deal-label/deal-label';
import ExpandableComponent from '../../nucleus/expandable-component/expandable-component';

const PaymentsAndInstallmentsWidget = ({ user }) => {
  if (!user || !user.id) { return null; }

  const tabs = ['Recent Payments', 'Recent Installments'];
  const filterOptions = [
    {
      display_name: <React.Fragment><Icon iconName="okay" /><span>Paid</span></React.Fragment>,
      value: 'Paid',
    },
    {
      display_name: <React.Fragment><Icon iconName="alarm" /><span>Upcoming</span></React.Fragment>,
      value: 'Upcoming',
    },
    {
      display_name: <React.Fragment><Icon iconName="warning" /><span>Past Due</span></React.Fragment>,
      value: 'Receivable',
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [isOpen, setIsOpen] = useLocalStorage('payments-and-installments-widget-is-open', true);
  const [selectedFilterOption, setSelectedFilterOption] = useState(filterOptions[0]);
  const [payments, setPayments] = useState([]);
  const [installments, setInstallments] = useState([]);

  const handleExpandToggle = () => {
    tracker.trackPaymentsWidgetToggle(!isOpen);
    setIsOpen(!isOpen);
  };

  const fetchPaymentsOrInstallments = async (which, fetch, set, transactionKey) => {
    try {
      setIsLoading(true);
      let activeProxy = null;
      const proxyResponse = await proxyService.currentActiveProxy();
      activeProxy = proxyResponse.results.find(access => access.is_active);
      const params = {
        ordering: which === 'payments' ? '-accounting_date' : '-installment_date',
        payment_status: [selectedFilterOption.value],
        page_size: 3,
        user_id: which === 'payments' ? activeProxy?.user_id || user.id : null,
        is_mta: which === 'installments' ? true : null,
      };
      const { results } = await fetch(params);

      // traunch details and installments endpoints do not currently surface all the deal info we want to display
      // so fetch each associated transaction to get that info
      const objects = await Promise.all(results.map(async (result) => {
        // handle case when transaction information is missing from payment resultss
        if (result[transactionKey]) {
          const {
            deal_type,
            rep_role,
            deal_life_cycle_stage,
            client,
            counter_party,
          } = await dealsService.fetchDeal(result[transactionKey].id);

          return {
            ...result,
            deal_type,
            rep_role,
            deal_life_cycle_stage,
            client,
            counter_party,
          };
        }
        return {
          ...result,
          deal_life_cycle_stage: '',
          deal_type: '',
          rep_role: '',
        };
      }));

      set(objects);
    } catch (error) {
      console.log('Error fetching payments or installments: ', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (activeTab === tabs[0]) {
      fetchPaymentsOrInstallments('payments', traunchDetailsService.fetchTraunchDetails, setPayments, 'transaction');
    } else if (activeTab === tabs[1]) {
      fetchPaymentsOrInstallments('installments', installmentsService.fetchAllInstallments, setInstallments, 'full_transaction');
    }
  }, [selectedFilterOption, activeTab]);

  const widgetHeader = (
    <div className="nd-tabnavs">
      <ul>
        {tabs.map(tab => (
          <li
            key={tab}
            className={activeTab === tab ? 'active' : ''}
            onClick={() => {
              setActiveTab(tab);
              tracker.trackPaymentsWidgetTabClicked(tab);
            }}
          >
            <a>{tab}</a>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <Widget
      className="payments-and-installments-widget"
      alternateHeader={widgetHeader}
      controls={<ExpandToggle isOpen={isOpen} onClick={handleExpandToggle} />}
    >
      <ExpandableComponent isOpen={isOpen}>
        <React.Fragment>
          <ButtonSegmentedGroup
            options={filterOptions}
            selected={selectedFilterOption}
            onChange={(option) => {
              setSelectedFilterOption(option);
              tracker.trackPaymentsWidgetFilterClicked(option.value, activeTab);
            }}
          />
          {activeTab === tabs[0] && (
            payments.map(payment => (
              <PaymentCard
                key={payment.id}
                payment={payment}
              />
            ))
          )}
          {activeTab === tabs[0] && !payments.length && !isLoading && (
            <div className="widget-content-row extra-padding">
              No payments found matching current filter.
            </div>
          )}
          {activeTab === tabs[0] && !!payments.length && (
            <a
              className="see-more-link"
              href="/commissions/my_payments"
              target="_self"
            >
              See More
            </a>
          )}
          {activeTab === tabs[1] && (
            installments.map(installment => (
              <InstallmentCard
                key={installment.id}
                installment={installment}
              />
            ))
          )}
          {activeTab === tabs[1] && !installments.length && !isLoading && (
            <div className="widget-content-row extra-padding">
              No installments found matching current filter.
            </div>
          )}
          {activeTab === tabs[1] && !!installments.length && (
            <Link
              className="see-more-link"
              to="/commissions/installments?is_mta=true"
            >
              See More
            </Link>
          )}
        </React.Fragment>
      </ExpandableComponent>
    </Widget>
  );
};

const getPaymentStatusIcon = ({ payment_status }) => {
  switch (payment_status) {
    case 'Paid':
      return 'okay';
    case 'Upcoming':
      return 'alarm';
    case 'Receivable':
      return 'warning';
    default:
      return '';
  }
};

const PaymentCard = ({ payment }) => {
  const getPaymentDate = (p) => {
    if (p.payment_status === 'Receivable') {
      const today = moment(new Date());
      const daysPastDue = moment(new Date(p.accounting_date));
      return `${today.diff(daysPastDue, 'days')} Days Past Due`;
    }
    return moment(p.accounting_date).format('M/D/YYYY');
  };

  const renderLink = () => {
    if (payment.transaction) {
      return (
        <Link
          to={`deals/detail/${payment.related_deal_id || payment.transaction.id}/overview`}
          onClick={() => {
            tracker.trackPaymentsWidgetLinkToDeal(payment.related_deal_id || payment.transaction.id, 'Recent Payments');
          }}
        >
          <h3 className={payment.payment_status.toLowerCase()}>{payment.related_deal_name || payment.name}</h3>
        </Link>
      );
    }
    return <h3 className={payment.payment_status.toLowerCase()}>{payment.name}</h3>;
  };

  return (
    <section className="widget-content-row">
      <div className="flex-row space-between margin-bottom">
        <div className="flex-row">
          <Icon iconName={getPaymentStatusIcon(payment)} />
          {renderLink()}
        </div>
        <DealLabel
          dealStage={payment.deal_life_cycle_stage}
          dealType={payment.deal_type || ''}
          repRole={payment.rep_role || ''}
        />
      </div>
      <div className="flex-row three-column">
        <div>
          <StackedLabeledItem
            label="Deal ID"
            value={payment.deal_id}
          />
          <StackedLabeledItem
            label={payment.payment_status === 'Paid' ? 'Date Paid' : 'Date Due'}
            value={getPaymentDate(payment)}
          />
        </div>
        <div>
          <StackedLabeledItem
            label="My Gross"
            value={<Currency value={payment.gross_amount} />}
          />
          <StackedLabeledItem
            label="My Net"
            value={<Currency value={payment.total_net_amount} />}
          />
        </div>
        <div>
          <StackedLabeledItem
            label={payment.rep_role !== 'consulting' ? payment.rep_role : 'Client'}
            value={payment.client && payment.client.name}
          />
          {payment.deal_type !== 'consulting' && (
            <StackedLabeledItem
              label={getCounterPartyLabel(payment)}
              value={payment.counter_party && payment.counter_party.name}
            />
          )}
        </div>
      </div>
    </section>
  );
};

const InstallmentCard = ({ installment }) => {
  const getInstallmentAmount = (i) => {
    if (i.payment_status === 'Paid') {
      return <Currency value={Number(i.paid_amount)} />;
    }
    return <Currency value={Number(i.receivable_amount)} />;
  };

  const finalPropertyAddress =
    installment.full_transaction &&
    installment.full_transaction.final_properties &&
    installment.full_transaction.final_properties[0] &&
    installment.full_transaction.final_properties[0].full_address;

  return (
    <div className="widget-content-row">
      <div className="flex-row space-between margin-bottom">
        <div className="flex-row">
          <Icon iconName={getPaymentStatusIcon(installment)} />
          {installment.related_deal_id && (
            <Link
              to={`/deals/detail/${installment.related_deal_id}/overview`}
              onClick={() => { tracker.trackPaymentsWidgetLinkToDeal(installment.related_deal_id, 'Recent Installments'); }}
            >
              <h3 className={installment.payment_status.toLowerCase()}>{installment.related_deal_name}</h3>
            </Link>
          )}
        </div>
        <DealLabel
          dealStage={installment.deal_life_cycle_stage}
          dealType={installment.deal_type || ''}
          repRole={installment.rep_role || ''}
        />
      </div>
      <div className="flex-row three-column">
        <div>
          <StackedLabeledItem
            label="Deal ID"
            value={installment.deal_id}
          />
          <StackedLabeledItem
            label={installment.payment_status === 'Paid' ? 'Date Paid' : 'Date Due'}
            value={formatPaymentDate(installment)}
          />
        </div>
        <div>
          <StackedLabeledItem
            label="Installment Amount"
            value={getInstallmentAmount(installment)}
          />
          <StackedLabeledItem
            label="Final Property"
            value={finalPropertyAddress}
          />
        </div>
        <div>
          <StackedLabeledItem
            label={installment.rep_role !== 'consulting' ? installment.rep_role : 'Client'}
            value={installment.client && installment.client.name}
          />
          {installment.deal_type !== 'consulting' && (
            <StackedLabeledItem
              label={getCounterPartyLabel(installment)}
              value={installment.counter_party && installment.counter_party.name}
            />
          )}
        </div>
      </div>
    </div>
  );
};

PaymentsAndInstallmentsWidget.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
};

PaymentsAndInstallmentsWidget.defaultProps = {
  user: {},
};

PaymentCard.propTypes = {
  payment: PropTypes.shape({
    client: PropTypes.shape({
      name: PropTypes.string,
    }),
    counter_party: PropTypes.shape({
      name: PropTypes.string,
    }),
    deal_id: PropTypes.string,
    deal_life_cycle_stage: PropTypes.string,
    deal_type: PropTypes.string,
    gross_amount: PropTypes.number,
    name: PropTypes.string,
    payment_status: PropTypes.string,
    related_deal_id: PropTypes.number,
    related_deal_name: PropTypes.string,
    rep_role: PropTypes.string,
    total_net_amount: PropTypes.number,
    transaction: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
};

InstallmentCard.propTypes = {
  installment: PropTypes.shape({
    client: PropTypes.shape({
      name: PropTypes.string,
    }),
    counter_party: PropTypes.shape({
      name: PropTypes.string,
    }),
    deal_id: PropTypes.number,
    deal_life_cycle_stage: PropTypes.string,
    deal_type: PropTypes.string,
    full_transaction: PropTypes.shape({
      final_properties: PropTypes.arrayOf(PropTypes.shape({
        full_address: PropTypes.string,
      })),
    }),
    payment_status: PropTypes.string,
    related_deal_id: PropTypes.number,
    related_deal_name: PropTypes.string,
    rep_role: PropTypes.string,
  }).isRequired,
};

export default PaymentsAndInstallmentsWidget;
