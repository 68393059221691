import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import omit from 'lodash/omit';

import traunchDetailService from '../../services/traunch-details-service';
import MyPaymentsTable from '../../components/commissions/my-payments-table';
import CommissionsFilter from '../../components/commissions/commissions-filter';
import CommissionsFilterTags from '../../components/commissions/commissions-filter-tags';
import LoadMore from '../../nucleus/load-more/load-more';
import ListActionHeader from '../../nucleus/list-action-header/list-action-header';
import ListSort from '../../nucleus/list-sort/list-sort';
import SearchInput from '../../nucleus/search/search-input';
import proxyService from '../../services/proxy-service';

const MyPayments = ({ user, featureFlags }) => {
  const sortingKeys = [
    {
      displayName: 'Payment Date',
      ascSortingKey: 'accounting_date',
      descSortingKey: '-accounting_date',
    },
    {
      displayName: 'Gross',
      ascSortingKey: '-gross_amount',
      descSortingKey: 'gross_amount',
    },
    {
      displayName: 'Net',
      ascSortingKey: '-total_net_amount',
      descSortingKey: 'total_net_amount',
    },
  ];

  const payments = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [cachedParams, setCachedParams] = useState(JSON.parse(window.localStorage.getItem('myPaymentsParams')) || {});
  const [params, setParams] = useState({
    page: 1,
    page_size: 100,
    user_id: user.id,
    ordering: sortingKeys[0].descSortingKey,
    payment_status: ['Paid', 'Receivable', 'Upcoming'],
    ...cachedParams,
  });

  useEffect(() => {
    proxyService.currentActiveProxy().then((res) => {
      const active = res.results.find(proxy => proxy.is_active);
      if (active && active.user_id !== user.id) {
        setParams({ ...params, user_id: active.user_id });
      }
    });
  }, []);

  useEffect(() => {
    let isCurrent = true;
    const cacheParams = (allParams) => {
      const paramKeysToOmit = ['page', 'page_size', 'is_mta', 'user_id'];
      const paramsToCache = omit(allParams, paramKeysToOmit);
      window.localStorage.setItem('myPaymentsParams', JSON.stringify(paramsToCache));
      setCachedParams(paramsToCache);
    };

    const handleFetch = async () => {
      setIsLoading(true);
      cacheParams(params);
      const response = await traunchDetailService.fetchTraunchDetails(params);

      if (isCurrent) {
        const newPayments = params.page === 1 ? response.results : payments.current.concat(response.results);
        payments.current = uniqBy(newPayments, 'id');
        setHasNextPage(!!response.next);
        setIsLoading(false);
      }
    };

    handleFetch();
    return () => { isCurrent = false; };
  }, [params]);

  const loadMorePayments = () => {
    setParams({
      ...params,
      page: params.page + 1,
    });
  };

  const handleFilterChange = (value, key) => {
    setParams({
      ...params,
      [key]: value,
      page: 1,
    });
  };

  const handleSearchTermChange = (search) => {
    setParams({
      ...params,
      search,
      page: 1,
    });
  };

  const handleSortChange = (ordering) => {
    setParams({
      ...params,
      ordering,
      page: 1,
    });
  };

  const handleFilterTagChange = (newParams) => {
    setParams({
      ...params,
      ...newParams,
      page: 1,
    });
  };

  return (
    <div className="card-default my-payments">
      <ListActionHeader
        filter={(
          <CommissionsFilter
            type="payments"
            currentParams={(typeof params.payment_status === 'string'
              ? { ...params, payment_status: [] }
              : params)}
            filterByKey={handleFilterChange}
          />

        )}
        search={(
          <SearchInput
            initValue={params.search}
            handleSearchTermChange={handleSearchTermChange}
            searchKey="payments"
          />
        )}
        sort={(
          <ListSort
            currentSortingKey={params.ordering}
            onChange={handleSortChange}
            options={sortingKeys}
          />
        )}
        isLoading={isLoading}
      />
      <CommissionsFilterTags
        currentParams={(typeof params.payment_status === 'string'
          ? { ...params, payment_status: [] }
          : params)}
        applyParams={handleFilterTagChange}
      />
      <MyPaymentsTable
        payments={payments.current}
        isLoading={isLoading}
        featureFlags={featureFlags}
      />
      <LoadMore
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        handleLoadMore={loadMorePayments}
      />
    </div>
  );
};

export default MyPayments;

MyPayments.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  featureFlags: PropTypes.shape({ proxy_user_flag: PropTypes.bool }),
};

MyPayments.defaultProps = {
  featureFlags: {},
};
