import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import { cloneDeep } from 'lodash';

import AutocompleteFlyout from '../autocomplete/autocomplete-flyout';
import {
  search,
  buildingLookup,
  getEDPSuggestions,
} from '../../services/esri-service';
import abbreviate from '../../utils/state-abbreviator';

class PropertyAutocomplete extends React.Component {
  static propTypes = {
    /** Callback for when a user clicks on a result */
    onSelect: PropTypes.func.isRequired,
    /** An ErrorMessage to show if field is required */
    errorMessage: PropTypes.string,
    /** The string you want to use in the input as a placeholder  */
    placeholder: PropTypes.string,
    initSearchTerm: PropTypes.string,
    filterResultsPredicate: PropTypes.func,
    dataE2e: PropTypes.string,
    onChange: PropTypes.func,
  };
  static defaultProps = {
    errorMessage: '',
    placeholder: 'Search Address',
    initSearchTerm: '',
    filterResultsPredicate: null,
    dataE2e: 'properties-autocomplete',
    onChange: () => {},
  };
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  onChange = (property) => {
    const { onSelect, onChange } = this.props;

    if (typeof property === 'string') {
      onChange(property);
    } else {
      onChange('');
    }

    const buildingLookupComplete = (response) => {
      const result = {
        magicKey: property.magicKey,
        name: get(response, 'candidates[0].attributes.PlaceName'),
        esriSearchText: get(response, 'candidates[0].address'),
        building: {
          address: get(response, 'candidates[0].attributes.StAddr'),
          city: get(response, 'candidates[0].attributes.City'),
          stateName: get(response, 'candidates[0].attributes.Region'),
          country: get(response, 'candidates[0].attributes.Country'),
        },
      };

      if (result.building.country === 'CAN') {
        result.building.postal_code = `${get(
          response,
          'candidates[0].attributes.Postal',
        )} ${get(response, 'candidates[0].attributes.PostalExt')}`;
      } else {
        result.building.postal_code = get(
          response,
          'candidates[0].attributes.Postal',
        );
      }

      if (result.building.stateName) {
        result.building.state_abbreviation = abbreviate(result.building.stateName);
      }
      const edp_property_id = get(
        response,
        'candidates[0].edp_property_id',
        null,
      );
      result.edp_property_id = edp_property_id;
      this.setState({ isLoading: false });
      onSelect(result);
    };

    if (property.unknownProperty) {
      const unknownProperty = {
        magicKey: uniqueId(),
        name: 'Unknown Property',
        edp_property_id: null,
        building: {
          address: null,
          city: null,
          stateName: null,
          postal_code: null,
          country: null,
        },
      };
      onSelect(unknownProperty, true);
      return;
    }
    if (property && typeof property !== 'string') {
      this.setState({ isLoading: true });
      if (property.attributes) {
        const modifiedSuggestion = cloneDeep(property);
        modifiedSuggestion.address = modifiedSuggestion.text;
        buildingLookupComplete({ candidates: [modifiedSuggestion] });
        this.setState({ isLoading: false });
        return;
      }
      const params = {
        singleLine: property.text,
        magicKey: property.magicKey,
        f: 'json',
      };
      buildingLookup(params)
        .then(buildingLookupComplete)
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  fetchAddresses = (params) => {
    const searchParams = {
      f: 'json',
      countryCode: 'USA,CAN,BR,AR,CL,CO,MX',
      text: params.search,
      maxSuggestions: 15,
    };
    return new Promise((resolve, reject) => {
      getEDPSuggestions({
        search: params.search,
      })
        .then((edpResponse) => {
          if (edpResponse.results.candidates.length > 0) {
            let candidates = edpResponse.results.candidates.map((candidate) => {
              const modifiedCandidate = { ...candidate };
              modifiedCandidate.text = candidate.address;
              delete modifiedCandidate.address;
              return modifiedCandidate;
            });
            const remainingLength = 15 - candidates.length;
            if (candidates.length < 15) {
              search(searchParams)
                .then((esriResponse) => {
                  const { suggestions } = esriResponse;
                  let uniqueSuggestions = suggestions.filter(suggestion =>
                    !candidates.some(candidate => candidate.text === suggestion.text));
                  if (uniqueSuggestions.length > 0) {
                    uniqueSuggestions = uniqueSuggestions.slice(
                      0,
                      remainingLength,
                    );
                    candidates = candidates.concat(uniqueSuggestions);
                    resolve(candidates);
                  } else {
                    resolve(candidates);
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              resolve(candidates);
            }
          } else {
            search(searchParams)
              .then((data) => {
                if (data.suggestions) {
                  resolve(data.suggestions);
                } else {
                  resolve([]);
                }
              })
              .catch((error) => {
                reject(error);
              });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  renderListItem = result => <div> {result.text}</div>;

  renderFooterContent = onSelect => (
    <p onClick={() => onSelect({ unknownProperty: true })}>
      Exact address match not found. Select as Unknown Property.
    </p>
  );

  render() {
    const {
      errorMessage,
      placeholder,
      initSearchTerm,
      filterResultsPredicate,
      dataE2e,
    } = this.props;
    const { isLoading } = this.state;
    const loadingMessage = isLoading && 'Loading Property Information';

    return (
      <div>
        <AutocompleteFlyout
          initSearchTerm={initSearchTerm}
          fetchRequest={this.fetchAddresses}
          filterResultsPredicate={filterResultsPredicate}
          renderFooterContent={this.renderFooterContent}
          onChange={this.onChange}
          placeholder={loadingMessage || placeholder}
          renderListItem={this.renderListItem}
          errorMessage={errorMessage}
          isLoading={isLoading}
          dataE2e={dataE2e}
        />
      </div>
    );
  }
}

export default PropertyAutocomplete;
