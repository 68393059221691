import { connect } from 'react-redux';
import { fetchDeals, fetchDealsAndTransactionsES, loadDealAndTransactionCount } from '../actions/deals-actions';
import { fetchDealTags } from '../actions/tags-actions';

import DealList from '../../components/property-details/deal-list';

const mapStateToProps = state => ({
  allDealTags: state.tags.dealTags,
  deals: state.deals.deals,
  dealCount: state.deals.dealCount,
  featureFlags: state.userOptions.userOptions.feature_flags,
  hasNextPage: state.deals.hasNextPage,
  isLoadingDeals: !!state.deals.requestId,
  user: state.userData.data,
});

const mapDispatchToProps = {
  fetchDeals,
  fetchDealsAndTransactionsES,
  fetchDealTags,
  loadDealAndTransactionCount,
};

// takes props sent from parent and merges with redux state and actions
const mergeProps = (state, actions, props) => ({
  ...state,
  ...actions,
  initQueryParams: {
    conversion_potential_min: 0,
    conversion_potential_max: 100,
    deal_life_cycle_stage: [],
    rep_role: [],
    tags: [],
    date_approved_min: null,
    date_approved_max: null,
    ordering: '-modified',
    page: 1,
    page_size: 25,
    search: '',
    is_untagged_deal: null,
    created_by: null,
    created_by_name: '',
    ...props.queryParams,
  },
  analyticProperties: props.analyticProperties,
});

const DealListContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(DealList);

export default DealListContainer;

