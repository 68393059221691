import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import propertiesService from '../../services/properties-service';
import LoadingIndicator from '../../nucleus/loading-indicator/loading-indicator';
import PropertyDetailHeader from '../../components/headers/property-detail-header';
import propertyDetailSubroutes from './property-detail-subroutes';

const analyticProperties = {
  actionPrefix: 'property_detail',
  categoryPrefix: 'Property Detail ',
};

class PropertyDetailPage extends React.Component {
  constructor(props) {
    super(props);

    if (props.tab !== 'deals') {
      this.redirectToNotFound();
      return null;
    }

    this.state = {
      building: null,
      transactionIds: [],
      isLoading: true,
    };
  }


  componentDidMount() {
    this.getBuilding();
    this.getBuildingDeals();
    document.title = 'Deal IQ | Property';
  }

  getBuilding = () => {
    // get building info from the selected property
    propertiesService.fetchBuilding(this.props.buildingId)
      .then((data) => {
        if (data.error && data.error === 404) {
          this.redirectToNotFound();
          return;
        }
        this.setState({
          building: data,
        });
      })
      .catch((error) => {
        console.log('Error fetching property: ', error);
      });
  };

  getBuildingDeals = () => {
    // get deals associated with the selected property
    const params = { building_id: this.props.buildingId };
    propertiesService.fetchProperties(params)
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const transactionIds = data.results[0].transaction_ids.filter(id => id !== null);
          this.setState({ transactionIds, isLoading: false });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.log('Error fetching deals: ', error);
        this.setState({ isLoading: false });
      });
  };

  redirectToNotFound = () => {
    window.location.replace('/notfound');
  };

  render() {
    const {
      buildingId,
      dealCount,
      url,
      tab,
      user,
    } = this.props;
    const { building, transactionIds, isLoading } = this.state;
    if (isLoading) {
      return <LoadingIndicator />;
    }
    // Create queryParams based on the count of transactionIds
    const queryParams = transactionIds.length > 30
      ? { building_ids: building.id }
      : { id: transactionIds };

    return (
      <div className="property-detail-page">
        <PropertyDetailHeader
          building={building}
          dealCount={dealCount}
          id={buildingId}
          tab={tab}
          url={url}
          user={user}
        />
        {propertyDetailSubroutes
          .map((route) => {
            const RouteComponent = route.component;
            return (
              <Route
                path={route.path}
                exact={route.exact}
                key={route.path}
                render={() => (<RouteComponent
                  analyticProperties={{
                    actionPrefix: `${analyticProperties.actionPrefix}${route.analyticProperties ? route.analyticProperties.actionPrefix : ''}`,
                    categoryPrefix: `${analyticProperties.categoryPrefix}${route.analyticProperties ? route.analyticProperties.categoryPrefix : ''}`,
                  }}
                  id={buildingId}
                  queryParams={queryParams}
                />)
                }
              />
            );
          })
        }
      </div>
    );
  }
}

PropertyDetailPage.propTypes = {
  buildingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dealCount: PropTypes.number.isRequired,
  tab: PropTypes.string,
  url: PropTypes.string.isRequired,
  user: PropTypes.shape({}).isRequired,
};
PropertyDetailPage.defaultProps = {
  tab: 'deals',
};

export default PropertyDetailPage;
