/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { round } from 'lodash';
// eslint-disable-next-line import/extensions
import { FaRegWindowMinimize } from 'react-icons/fa';
import { Button, IconButton, Checkbox, Icon } from '@cbrebuild/blocks';
import './lease-abstraction.scss';
import dealsService from '../../services/deals-service';
import vouchersService from '../../services/voucher/vouchers-service';
import * as compDataService from '../../services/voucher/comp-data-service';
import companiesService from '../../services/companies-service';
import considerationsService from '../../services/voucher/considerations-service';
import contactsService from '../../services/contacts-service';
import LeaseDetails from './lease-details/lease-details';
import authService from '../../services/auth-service';
import LeaseComp from './lease-comp/lease-comp';
import Financials from './financials/financials';
import countries from '../../data/countries.json';
import states from '../../data/states.json';
import { validatePayload, getRandomId } from '../../utils/validation-utils';
import validationSchema from './validation-schema';
import useFileAbstractionFields from '../../hooks/useFileAbstractionFields';
import userEventService from '../../services/user-event-service';
import { fetchAttachmentsViewLink } from '../../services/attachments-service';

import { toast } from '../../redux/actions/toasts-actions';
import LeaseAbstractionContext from './contexts/leaseAbstractionContext';

/* global CONFIG */
const { apiUrl } = CONFIG;

const FileModal = (props) => {
  const {
    hideDataModal,
    closeDataModal,
    results,
    deal,
    file,
    analyticProperties: { actionPrefix, categoryPrefix },
  } = props;
  const getFilteredStates = (countryName = 'United States') => {
    const found = countries.find(c => c.display_name === countryName);
    return states.filter(s => s.country_id === found?.country_id);
  };
  const fileId = file.id;
  const dispatch = useDispatch();

  const tenantImprovementDetailOptions = [
    { display_name: 'Allowance', value: 'Allowance' },
    { display_name: 'As Is', value: 'As Is' },
    { display_name: 'Spec Suite', value: 'Spec Suite' },
    { display_name: 'Turn Key', value: 'Turn Key' },
  ];

  const isScreenWidthGreaterThan1200 = () => window.innerWidth > 1800;
  const [isMagnified] = useState(isScreenWidthGreaterThan1200);
  const responseData = useRef(null);
  const [showMoreInstructions, setShowMoreInstructions] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('lease-details');
  const [tAndCState, setTAndCState] = useState({
    'lease-details': false,
    'lease-comp': false,
    financials: false,
  });
  const [voucherId, setVoucherId] = useState(null);
  const [commercialPropertyId, setCommercialPropertyId] = useState(null);
  const [counterPartyCompanyId, setCounterPartyCompanyId] = useState(null);
  const [tenantContactId, setTenantContactId] = useState(null);
  const [landlordContactId, setLandlordContactId] = useState(null);
  const [clientCompanyId, setClientCompanyId] = useState(null);
  const [repRole, setRepRole] = useState(null);
  const [internalCompId, setInternalCompId] = useState(null);
  const [propertyOptions, setPropertyOptions] = useState([
    { display_name: 'Select', value: null },
  ]);
  const [fieldErrorMap, setFieldErrorMap] = useState({});
  // State for all the modal fields
  const [modalFieldState, dispatchModalFieldState] = useFileAbstractionFields();
  const [responseState, dispatchResponseState] = useFileAbstractionFields();
  const [viewLink, setViewLink] = useState('');
  const [dealName, setDealName] = useState('');
  const [spaceData, setSpaceData] = useState([]);
  const dateFiledValidationVariables = [
    'isLeaseStartDateValid',
    'isLeaseEndDateValid',
    'isLeaseExecutionDateValid',
    'isLeaseOccupancyDateValid',
  ];

  const updateAttachmentsExtract = (id, params) => () => {
    const url = `${apiUrl}/mbb/extractupdate/${id}`;
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status !== 200) {
          dispatch(toast('Error updating data. Please try again.'));
        } else {
          dispatch(toast('Data imported successfully.'));
        }
        return response;
      })
      .catch((error) => {
        dispatch(toast('Error updating data. Please try again.'));
        console.error('Error:', error);
      });
  };


  useEffect(() => {
    const handleFileViewer = async () => {
      try {
        const link = await fetchAttachmentsViewLink(fileId);
        setViewLink(link);
      } catch (error) {
        console.error('Error fetching view link:', error);
      }
    };
    const handleSpaceObject = async () => {
      try {
        const spaceObj = await vouchersService.fetchCommercialProperty(deal);
        const spaceProps = spaceObj.results[0].spaces;
        setSpaceData(spaceProps);
      } catch (error) {
        console.error('Error fetching space object:', error);
      }
    };
    handleSpaceObject();
    handleFileViewer();
  }, [fileId, deal]);

  const baseUrl = window.location.origin;
  // const pdfViewerUrl = `/src/libraries/pdf-js/web/viewer.html?file=${encodeURIComponent(viewLink)}`;
  const pdfViewerUrl = `${baseUrl}/react-app/libraries/pdf-js/web/viewer.html?file=${encodeURIComponent(viewLink)}`;

  useEffect(() => {
    // Ensure the iframe is loaded before trying to access its content
    const iframe = document.getElementById('pdf-js-viewer');
    iframe.onload = () => {
      const viewerWindow = iframe.contentWindow;
      // Expose the PDFViewerApplication object to the global window object
      window.PDFViewerApplication = viewerWindow.PDFViewerApplication;
    };
  }, [pdfViewerUrl]);

  const goToPage = (pageNumber) => {
    if (window.PDFViewerApplication) {
      window.PDFViewerApplication.page = parseInt(pageNumber, 10);
    }
  };

  useEffect(() => {
    const fetchContacts = async (params = {
      ordering: '-is_primary',
      page: 1,
      page_size: 25,
      deal,
    }) => {
      try {
        const response = await contactsService.getContacts(params);
        if (response && response.results) {
          const tenantContact = response.results.find(contact => contact.contact_type === 'tenant');
          const landlordContact = response.results.find(contact => contact.contact_type === 'landlord');

          if (tenantContact) {
            setTenantContactId(tenantContact.id);
          } else {
            console.error('Tenant contact not found');
          }

          if (landlordContact) {
            setLandlordContactId(landlordContact.id);
          } else {
            console.error('Landlord contact not found');
          }
        } else {
          console.error('No data found in response');
        }
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };
    const url = `${apiUrl}/mbb/deals/${deal}`;
    fetch(url, {
      headers: {
        Authorization: `JWT ${authService.getToken()}`,
      },
    })
      .then(response => response.json())
      .then((data) => {
        setDealName(data.name);
        responseData.current = data;
        setVoucherId(data.voucher);
        setInternalCompId(data.internalcomp);
        setCounterPartyCompanyId(data.counter_party.id);
        setClientCompanyId(data.client.id);
        setCommercialPropertyId(data.voucher_commercial_properties[0].id);
        fetchContacts();
        setRepRole(data.rep_role);
      })
      .catch(error => console.error('Error fetching data:', error));
    vouchersService.getPropertyOptions().then((res) => {
      if (
        res.actions.POST.voucher_property_type_category.choices.length > 0 &&
        propertyOptions.length === 1
      ) {
        setPropertyOptions(propertyOptions.concat(res.actions.POST.voucher_property_type_category.choices));
      }
    });
  }, [deal, propertyOptions]); // The empty array ensures this effect runs only once after the initial render

  // Field change handlers for LD Lease Information

  const modalFieldChangeHandler = useCallback(
    (field, value, type = 'single') => {
      dispatchModalFieldState({
        field,
        value,
        type,
      });
      if (!dateFiledValidationVariables.includes(field)) {
        setTAndCState({ ...tAndCState, [activeTab]: false });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatchModalFieldState],
  );

  // Helper function to check if a value is not undefined
  function isValueUpdated(value) {
    return value !== undefined;
  }

  // Helper function to check if a value is not null or an empty string
  function isValueEmpty(value) {
    return value !== null && value !== '' && value !== 0 && value !== '0';
  }

  const isConsiderationSingleAndEmpty = (customConsiderationArray) => {
    // Check if the array has only one element
    if (customConsiderationArray.length !== 1) {
      return false;
    }

    // Get the single element
    const item = customConsiderationArray[0];

    // Define the keys to check
    const keysToCheck = ['amount', 'total_units', 'commission_percent'];

    // Check if all the sub-elements are null or empty strings
    return keysToCheck.every(key => item[key] === null || item[key] === '');
  };

  const handleCheckBoxChecked = ({ target: { checked } }) => {
    setTAndCState({ ...tAndCState, [activeTab]: checked });
  };

  const setResponseState = useCallback(
    (field, value, type = 'single') => {
      dispatchResponseState({
        field,
        value,
        type,
      });
    },
    [dispatchResponseState],
  );

  const updateAttachmentsProps = (id, params) => () => {
    const url = `${apiUrl}/mbb/attachmentprops/${id}`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    };
    return fetch(url, options)
      .then(() => {
        const value_object = {
          value: modalFieldState[`${params.stateVariable}`].value,
        };
        console.log('Updating Response State with: ', value_object);
        setResponseState(params.stateVariable, value_object, 'single');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };


  const individualModalFieldChangeHandler = (params) => {
    const {
      stateVariable,
      child_key,
      child_value,
      parent_key,
    } = params;

    // Get initial state
    const stateField = responseState[stateVariable];
    if (!stateField) return;

    // Helper to get field value based on key
    const getFieldValue = (field, key) => {
      switch (key) {
        case 'rent_escalation_dollar':
          return field.value.rent_escalation_dollar;
        case 'rent_escalation_percent':
          return field.value.rent_escalation_percent;
        default:
          return field.value;
      }
    };

    // Get and normalize values for comparison
    const previousValue = getFieldValue(stateField, child_key);
    const normalizedChildValue = String(child_value).trim();
    const normalizedResponseValue = String(previousValue).trim();

    // Skip if values are equal
    if (normalizedChildValue === normalizedResponseValue) return;

    // Update attachmentprops
    dispatch(updateAttachmentsProps(fileId, params));

    // Track event
    const eventData = {
      eventAction: 'lae_individual_field_updates',
      eventCategory: 'Individual Field Value Changed',
      eventValue: {
        parentKey: parent_key,
        childKey: child_key,
        currentValue: child_value,
        previousValue,
        fileId,
        fieldName: modalFieldState[stateVariable]?.trackingID,
      },
    };

    const eventConfig = { actionPrefix, categoryPrefix };
    userEventService.trackEvent(eventData, eventConfig);
  };

  const trackImportData = () => {
    userEventService.trackEvent(
      {
        eventAction: 'import_data_clicked',
        eventCategory: 'File Action',
        eventValue: {
          fileId,
          tab: activeTab,
        },
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  };

  useEffect(() => {
    console.log('FX RESULTS', results);
    const initializeField = (
      fieldName,
      valuePath,
      basePath,
      transformFn = val => val,
      type = 'single',
    ) => {
      const fieldInfo =
        valuePath
          .split('.')
          .reduce(
            (acc, part) => acc && acc[part],
            results[0]?.data_extract?.[basePath],
          ) || '';
      const multipleFieldValues = transformFn(fieldInfo.value);
      let fieldInfoValue =
        type === 'multiple'
          ? {
            [`${fieldName}`]: {
              value: fieldInfo.value,
              toolTip: {
                section: fieldInfo.section,
                paragraph: fieldInfo.paragraph,
                pageIndex: fieldInfo.page_index
                  ? `${fieldInfo.page_index}*`
                  : fieldInfo.page_index,
              },
            },
            ...multipleFieldValues,
          }
          : {
            value: transformFn(fieldInfo.value),
            toolTip: {
              section: fieldInfo.section,
              paragraph: fieldInfo.paragraph,
              pageIndex: fieldInfo.page_index
                ? `${fieldInfo.page_index}*`
                : fieldInfo.page_index,
            },
          };
      if (valuePath === 'rent_escalation_is_custom') {
        fieldInfoValue = {
          ...fieldInfoValue,
          value: fieldInfo.value.toLowerCase() === 'true' ? 'Custom' : 'Fixed',
        };
      } else if (valuePath === 'rent_escalation_is_percent') {
        fieldInfoValue = {
          ...fieldInfoValue,
          value: fieldInfo.value.toLowerCase() === 'true' ? '%' : '$',
        };
      } else if (valuePath === 'rent_escalation_custom') {
        const customValue = (valueArray) => {
          if (Array.isArray(valueArray) && valueArray.length > 0) {
            return valueArray.map(item => ({
              id: getRandomId(),
              months: item.months,
              rent: {
                is_percent: item.is_percent.toLowerCase() === 'true',
                rent_dollar: item.rent_dollar,
                rent_percent: item.rent_percent,
              },
            }));
          }
          return [
            {
              id: getRandomId(),
              rent: {
                is_percent: false,
                rent_dollar: '',
                rent_percent: '',
              },
              months: '',
            },
          ];
        };
        fieldInfoValue = {
          ...fieldInfoValue,
          value: customValue(fieldInfo.value),
        };
      } else if (valuePath === 'rent_schedule') {
        const customValue = (valueArray) => {
          if (Array.isArray(valueArray) && valueArray.length > 0) {
            return valueArray.map(item => ({
              id: getRandomId(),
              consideration_type: item.consideration_type,
              total_units: item.total_units,
              amount: item.amount,
              consideration_amount: item.consideration_amount,
              commission_percent: item.commission_percent,
              commission_dollars: item.commission_amount,
              commission_amount: item.commission_amount,
            }));
          }
          return [
            {
              id: getRandomId(),
              consideration_type: 'Monthly Rent',
              total_units: '',
              amount: '',
              consideration_amount: '',
              commission_percent: '',
              commission_dollars: '',
              commission_amount: '',
            },
          ];
        };
        fieldInfoValue = {
          ...fieldInfoValue,
          value: customValue(fieldInfo.value),
        };
      } else if (valuePath === 'rent_escalation_dollar') {
        const rent_escalation_is_percent =
          'rent_escalation_is_percent'
            .split('.')
            .reduce(
              (acc, part) => acc && acc[part],
              results[0]?.data_extract?.[basePath],
            ) || '';
        const rent_escalation_dollar =
          'rent_escalation_dollar'
            .split('.')
            .reduce(
              (acc, part) => acc && acc[part],
              results[0]?.data_extract?.[basePath],
            ) || '';
        const rent_escalation_percent =
          'rent_escalation_percent'
            .split('.')
            .reduce(
              (acc, part) => acc && acc[part],
              results[0]?.data_extract?.[basePath],
            ) || '';
        fieldInfoValue = {
          ...fieldInfoValue,
          value: {
            rent_escalation_is_percent:
              rent_escalation_is_percent.value.toString().toLowerCase() ===
              'true',
            rent_escalation_dollar: rent_escalation_dollar.value,
            rent_escalation_percent: rent_escalation_percent.value,
          },
        };
      }
      modalFieldChangeHandler(fieldName, fieldInfoValue, type);
      setResponseState(fieldName, fieldInfoValue, type);
    };
    // eslint-disable-next-line react/prop-types
    if (results && results.length > 0) {
      // Lease Info
      initializeField('propertyName', 'property_name', 'lease_info');
      initializeField(
        'country',
        'country',
        'lease_info',
        initialCountry => ({
          filteredStatesForProperty: {
            value: getFilteredStates(initialCountry),
          },
        }),
        'multiple',
      );
      initializeField('propertyType', 'property_type_subtype', 'lease_info');
      initializeField(
        'leaseStartDate',
        'lease_start_date',
        'lease_info',
        // convertDateFormat,
      );
      initializeField(
        'leaseEndDate',
        'lease_expiration_date',
        'lease_info',
        // convertDateFormat,
      );
      initializeField(
        'leaseExecutionDate',
        'lease_execution_date',
        'lease_info',
        // convertDateFormat,
      );
      initializeField(
        'leaseOccupancyDate',
        'lease_occupancy_date',
        'lease_info',
        // convertDateFormat,
      );
      initializeField('address', 'address', 'lease_info');
      initializeField('city', 'city', 'lease_info');
      initializeField('stateName', 'state_province', 'lease_info');
      initializeField('zip', 'zip', 'lease_info');
      initializeField('isASublease', 'is_sublease', 'lease_info');
      initializeField('isListedByCBRE', 'is_listed_by_cbre', 'lease_info');
      initializeField('isWholeBuilding', 'is_whole_building', 'lease_info');
      initializeField('parcelSize', 'parcel_size', 'lease_info');
      initializeField('suite', 'suite', 'lease_info');
      initializeField('floorChange', 'floor_number', 'lease_info');
      initializeField('squareFeet', 'square_footage', 'lease_info');

      // tenant_buyer_info fields
      initializeField('tenantCompanyName', 'org_name', 'tenant_buyer_info');
      initializeField(
        'tenantCountryName',
        'country',
        'tenant_buyer_info',
        initialCountry => ({
          filteredStatesForTenant: { value: getFilteredStates(initialCountry) },
        }),
        'multiple',
      );
      initializeField('tenantAddressLine1', 'address', 'tenant_buyer_info');
      initializeField('tenantAddressLine2', 'address2', 'tenant_buyer_info');
      initializeField('tenantCity', 'city', 'tenant_buyer_info');
      initializeField('tenantStateName', 'state_province', 'tenant_buyer_info');
      initializeField('tenantZip', 'zip', 'tenant_buyer_info');
      initializeField('tenantPOCPhone', 'contact_phone', 'tenant_buyer_info');
      initializeField('tenantPOCEmail', 'contact_email', 'tenant_buyer_info');
      initializeField(
        'tenantPOCFirstName',
        'contact_first_name',
        'tenant_buyer_info',
      );
      initializeField(
        'tenantPOCLastName',
        'contact_last_name',
        'tenant_buyer_info',
      );
      initializeField(
        'tenantPOCJobTitle',
        'contact_title',
        'tenant_buyer_info',
      );

      // landlord_seller_info fields
      initializeField(
        'landlordCompanyName',
        'org_name',
        'landlord_seller_info',
      );
      initializeField(
        'landlordCountryName',
        'country',
        'landlord_seller_info',
        initialCountry => ({
          filteredStatesForLandlord: {
            value: getFilteredStates(initialCountry),
          },
        }),
        'multiple',
      );
      initializeField(
        'landlordAddressLine1',
        'address',
        'landlord_seller_info',
      );
      initializeField(
        'landlordAddressLine2',
        'address2',
        'landlord_seller_info',
      );
      initializeField('landlordCity', 'city', 'landlord_seller_info');
      initializeField(
        'landlordStateName',
        'state_province',
        'landlord_seller_info',
      );
      initializeField('landlordZip', 'zip', 'landlord_seller_info');
      initializeField(
        'landlordPOCPhone',
        'contact_phone',
        'landlord_seller_info',
      );
      initializeField(
        'landlordPOCEmail',
        'contact_email',
        'landlord_seller_info',
      );
      initializeField(
        'landlordPOCFirstName',
        'contact_first_name',
        'landlord_seller_info',
      );
      initializeField(
        'landlordPOCLastName',
        'contact_last_name',
        'landlord_seller_info',
      );
      initializeField(
        'landlordPOCJobTitle',
        'contact_title',
        'landlord_seller_info',
      );

      // comp_info fields
      initializeField(
        'tenantRepresentCompany',
        'tenant_represented_by',
        'comp_info',
      );
      initializeField(
        'tenantRepresentAgent',
        'tenant_represented_by_agent',
        'comp_info',
      );
      initializeField(
        'landlordRepresentCompany',
        'landlord_represented_by',
        'comp_info',
      );
      initializeField(
        'landlordRepresentAgent',
        'landlord_represented_by_agent',
        'comp_info',
      );
      initializeField('baseRent', 'base_rent', 'comp_info');
      // New comp fields
      initializeField('freeRent', 'free_rent', 'comp_info');
      initializeField(
        'leaseEscalation',
        'rent_escalation_is_custom',
        'comp_info',
      );
      // initializeField(
      //   'rentEscalationIsPercent',
      //   'rent_escalation_is_percent',
      //   'comp_info',
      // );
      // initializeField(
      //   'rentEscalationDollar',
      //   'rent_escalation_dollar',
      //   'comp_info',
      // );
      // initializeField(
      //   'rentEscalationPercent',
      //   'rent_escalation_percent',
      //   'comp_info',
      // );
      initializeField(
        'customLeaseEscalation',
        'rent_escalation_custom',
        'comp_info',
      );
      //
      initializeField(
        'fixedLeaseEscalation',
        'rent_escalation_dollar',
        'comp_info',
      );
      //
      initializeField(
        'tenantImprovementAllowance',
        'tenant_improvement_allowance_amount_per_sqft',
        'comp_info',
      );
      initializeField(
        'tenantImprovementDetails',
        'tenant_improvements_detail',
        'comp_info',
      );
      initializeField('parkingRatio', 'parking_ratio', 'comp_info');
      // Financials fields
      initializeField(
        'considerationIsPercent',
        'considerations_is_percent',
        'consideration_info',
      );
      initializeField(
        'customConsideration',
        'rent_schedule',
        'consideration_info',
      );
    }
  }, [modalFieldChangeHandler, setResponseState, results]);

  const validateLeaseAbstractionFields = () => {
    const { isPayloadValid, errorMap } = validatePayload(
      validationSchema,
      modalFieldState,
    );
    setFieldErrorMap(errorMap);

    return {
      isPayloadValid,
      errorMessages: Object.entries(errorMap)
        .map(([field, message]) => `${field}: ${message}`)
        .join(', '),
    };
  };

  // eslint-disable-next-line consistent-return
  const handleUpdateAll = () => {
    // Validate the fields
    const { isPayloadValid, errorMessages } = validateLeaseAbstractionFields();

    // Initialize patch objects
    const dealUpdatePatch = {};
    const voucherUpdatePatch = {};
    const commercialPropertyUpdatePatch = {};
    const internalCompUpdatePatch = {};
    const counterPartyCompanyUpdatePatch = {};
    const clientCompanyUpdatePatch = {};
    const tenantContactUpdatePatch = {};
    const landlordContactUpdatePatch = {};
    const considerationsUpdatePatch = {};

    // gather fields for patch to attachments extract api
    const updatedFields = {
      tenant_buyer_info: {},
      landlord_seller_info: {},
      lease_info: {},
      comp_info: {},
      consideration_info: {},
    };

    const propertyTypeValue = modalFieldState.propertyType.value;
    const isPropertyTypeValid = propertyOptions.some(option => option.value === propertyTypeValue);

    const tenantImprovementDetailsValue =
      modalFieldState.tenantImprovementDetails.value;
    const isTenantImprovementDetailsValid = tenantImprovementDetailOptions.some(option => option.value === tenantImprovementDetailsValue);

    if (
      isPayloadValid &&
      modalFieldState.isLeaseStartDateValid &&
      modalFieldState.isLeaseEndDateValid &&
      modalFieldState.isLeaseExecutionDateValid &&
      modalFieldState.isLeaseOccupancyDateValid &&
      modalFieldState.tenantPOCPhoneValid &&
      modalFieldState.landlordPOCPhoneValid &&
      isPropertyTypeValid &&
      isTenantImprovementDetailsValid
    ) {
      if (activeTab === 'lease-details') {
        // Conditionally add properties to dealUpdatePatch
        let leaseStartDateValue = modalFieldState.leaseStartDate.value;
        leaseStartDateValue =
          leaseStartDateValue === null ? '' : leaseStartDateValue;
        const isLeaseStartDateUpdated =
          leaseStartDateValue.length > 0 &&
          isValueUpdated(leaseStartDateValue) &&
          isValueEmpty(leaseStartDateValue);

        if (isLeaseStartDateUpdated) {
          dealUpdatePatch.date_lease_from = leaseStartDateValue;
        }
        updatedFields.lease_info.lease_start_date = isLeaseStartDateUpdated
          ? leaseStartDateValue
          : null;

        let leaseExecutionDateValue = modalFieldState.leaseExecutionDate.value;
        leaseExecutionDateValue =
          leaseExecutionDateValue === null ? '' : leaseExecutionDateValue;
        const isLeaseExecutionDateUpdated =
          leaseExecutionDateValue.length > 0 &&
          isValueUpdated(leaseExecutionDateValue) &&
          isValueEmpty(leaseExecutionDateValue);

        if (isLeaseExecutionDateUpdated) {
          dealUpdatePatch.date_lease_execution = leaseExecutionDateValue;
        }
        updatedFields.lease_info.lease_execution_date =
          isLeaseExecutionDateUpdated ? leaseExecutionDateValue : null;

        let leaseOccupancyDateValue = modalFieldState.leaseOccupancyDate.value;
        leaseOccupancyDateValue =
          leaseOccupancyDateValue === null ? '' : leaseOccupancyDateValue;
        const isLeaseOccupancyDateUpdated =
          leaseOccupancyDateValue.length > 0 &&
          isValueUpdated(leaseOccupancyDateValue) &&
          isValueEmpty(leaseOccupancyDateValue);

        if (isLeaseOccupancyDateUpdated) {
          dealUpdatePatch.date_lease_occupancy = leaseOccupancyDateValue;
        }
        updatedFields.lease_info.lease_occupancy_date =
          isLeaseOccupancyDateUpdated ? leaseOccupancyDateValue : null;

        if (isValueUpdated(modalFieldState.isASublease.value)) {
          if (isValueEmpty(modalFieldState.isASublease.value)) {
            dealUpdatePatch.is_sublease = modalFieldState.isASublease.value;
          }
          updatedFields.lease_info.is_sublease =
            modalFieldState.isASublease.value;
        }

        // Update the deal with a single API call if dealUpdatePatch is not empty
        if (Object.keys(dealUpdatePatch).length > 0) {
          dealsService.updateDeal(deal, dealUpdatePatch);
        }

        // Conditionally add properties to voucherUpdatePatch
        let leaseEndDateValue = modalFieldState.leaseEndDate.value;
        leaseEndDateValue = leaseEndDateValue === null ? '' : leaseEndDateValue;
        const isLeaseEndDateUpdated =
          leaseEndDateValue.length > 0 &&
          isValueUpdated(leaseEndDateValue) &&
          isValueEmpty(leaseEndDateValue);

        if (isLeaseEndDateUpdated) {
          voucherUpdatePatch.lease_expiration_date = leaseEndDateValue;
        }
        updatedFields.lease_info.lease_expiration_date = isLeaseEndDateUpdated
          ? leaseEndDateValue
          : null;

        // Update the voucher if voucherUpdatePatch is not empty
        if (Object.keys(voucherUpdatePatch).length > 0) {
          vouchersService.updateVoucher(voucherId, voucherUpdatePatch);
        }

        // Conditionally add properties to commercialPropertyUpdatePatch
        if (isValueUpdated(modalFieldState.propertyName.value)) {
          if (isValueEmpty(modalFieldState.propertyName.value)) {
            commercialPropertyUpdatePatch.name =
              modalFieldState.propertyName.value;
          }
          updatedFields.lease_info.property_name =
            modalFieldState.propertyName.value;
        }
        if (isValueUpdated(modalFieldState.address.value)) {
          if (isValueEmpty(modalFieldState.address.value)) {
            commercialPropertyUpdatePatch.address =
              modalFieldState.address.value;
          }
          updatedFields.lease_info.address = modalFieldState.address.value;
        }
        if (isValueUpdated(modalFieldState.country.value)) {
          if (isValueEmpty(modalFieldState.country.value)) {
            commercialPropertyUpdatePatch.country =
              modalFieldState.country.value;
          }
          updatedFields.lease_info.country = modalFieldState.country.value;
        }
        if (isValueUpdated(modalFieldState.city.value)) {
          if (isValueEmpty(modalFieldState.city.value)) {
            commercialPropertyUpdatePatch.city = modalFieldState.city.value;
          }
          updatedFields.lease_info.city = modalFieldState.city.value;
        }
        if (isValueUpdated(modalFieldState.stateName.value)) {
          if (isValueEmpty(modalFieldState.stateName.value)) {
            commercialPropertyUpdatePatch.state_abbreviation =
              modalFieldState.stateName.value;
          }
          updatedFields.lease_info.state_province =
            modalFieldState.stateName.value;
        }
        if (isValueUpdated(modalFieldState.zip.value)) {
          if (isValueEmpty(modalFieldState.zip.value)) {
            commercialPropertyUpdatePatch.postal_code =
              modalFieldState.zip.value;
          }
          updatedFields.lease_info.zip = modalFieldState.zip.value;
        }
        if (isValueUpdated(modalFieldState.propertyType.value)) {
          if (isValueEmpty(modalFieldState.propertyType.value)) {
            commercialPropertyUpdatePatch.voucher_property_type_category =
              modalFieldState.propertyType.value;
          }
          updatedFields.lease_info.property_type_subtype =
            modalFieldState.propertyType.value;
        }
        if (isValueUpdated(modalFieldState.parcelSize.value)) {
          if (isValueEmpty(modalFieldState.parcelSize.value)) {
            commercialPropertyUpdatePatch.parcel_size =
              modalFieldState.parcelSize.value;
          }
          updatedFields.lease_info.parcel_size =
            modalFieldState.parcelSize.value;
        }
        if (isValueUpdated(modalFieldState.isListedByCBRE.value)) {
          if (isValueEmpty(modalFieldState.isListedByCBRE.value)) {
            commercialPropertyUpdatePatch.is_listed =
              modalFieldState.isListedByCBRE.value;
          }
          updatedFields.lease_info.is_listed_by_cbre =
            modalFieldState.isListedByCBRE.value;
        }
        if (modalFieldState.isWholeBuilding.value.toLowerCase() === 'true') {
          if (isValueUpdated(modalFieldState.isWholeBuilding.value)) {
            if (isValueEmpty(modalFieldState.isWholeBuilding.value)) {
              commercialPropertyUpdatePatch.whole_building =
                modalFieldState.isWholeBuilding.value.toLowerCase();
            }
            updatedFields.lease_info.is_whole_building =
              modalFieldState.isWholeBuilding.value.toLowerCase();
          }
          if (isValueUpdated(modalFieldState.squareFeet.value)) {
            if (isValueEmpty(modalFieldState.squareFeet.value)) {
              commercialPropertyUpdatePatch.building_total_square_feet =
                modalFieldState.squareFeet.value;
            }
            updatedFields.lease_info.square_footage =
              modalFieldState.squareFeet.value;
          }
          updatedFields.lease_info.suite = '';
          updatedFields.lease_info.floor_number = '';
        }
        if (modalFieldState.isWholeBuilding.value.toLowerCase() === 'false') {
          const space = {
            address_line2: spaceData[0].address_line2,
            floor: spaceData[0].floor,
            lease_expiration_date: null,
            lease_start_date: null,
            space_total_square_feet: spaceData[0].space_total_square_feet,
          };
          if (isValueUpdated(modalFieldState.isWholeBuilding.value)) {
            if (isValueEmpty(modalFieldState.isWholeBuilding.value)) {
              commercialPropertyUpdatePatch.whole_building =
                modalFieldState.isWholeBuilding.value.toLowerCase();
            }
            updatedFields.lease_info.is_whole_building =
              modalFieldState.isWholeBuilding.value.toLowerCase();
          }
          if (isValueUpdated(modalFieldState.suite.value)) {
            if (isValueEmpty(modalFieldState.suite.value)) {
              space.address_line2 = modalFieldState.suite.value;
            }
            updatedFields.lease_info.suite = modalFieldState.suite.value;
          }
          if (isValueUpdated(modalFieldState.floorChange.value)) {
            if (isValueEmpty(modalFieldState.floorChange.value)) {
              space.floor = modalFieldState.floorChange.value;
            }
            updatedFields.lease_info.floor_number =
              modalFieldState.floorChange.value;
          }
          if (isValueUpdated(modalFieldState.squareFeet.value)) {
            if (isValueEmpty(modalFieldState.squareFeet.value)) {
              space.space_total_square_feet = parseInt(
                modalFieldState.squareFeet.value,
                10,
              );
            }
            updatedFields.lease_info.square_footage =
              modalFieldState.squareFeet.value;
          }

          if (Object.keys(space).length > 2) {
            commercialPropertyUpdatePatch.spaces = [space];
          }
        }

        // Update the commercial property if commercialPropertyUpdatePatch is not empty
        if (Object.keys(commercialPropertyUpdatePatch).length > 0) {
          vouchersService.updateCommercialProperty(
            commercialPropertyId,
            commercialPropertyUpdatePatch,
          );
        }

        // Conditionally add properties for landlordCompany/Counter_party to companyUpdatePatch
        if (isValueUpdated(modalFieldState.landlordCompanyName.value)) {
          if (isValueEmpty(modalFieldState.landlordCompanyName.value)) {
            counterPartyCompanyUpdatePatch.name =
              modalFieldState.landlordCompanyName.value;
          }
          updatedFields.landlord_seller_info.org_name =
            modalFieldState.landlordCompanyName.value;
        }
        if (isValueUpdated(modalFieldState.landlordCountryName.value)) {
          if (isValueEmpty(modalFieldState.landlordCountryName.value)) {
            counterPartyCompanyUpdatePatch.country =
              modalFieldState.landlordCountryName.value;
          }
          updatedFields.landlord_seller_info.country =
            modalFieldState.landlordCountryName.value;
        }
        if (isValueUpdated(modalFieldState.landlordCity.value)) {
          if (isValueEmpty(modalFieldState.landlordCity.value)) {
            counterPartyCompanyUpdatePatch.city =
              modalFieldState.landlordCity.value;
          }
          updatedFields.landlord_seller_info.city =
            modalFieldState.landlordCity.value;
        }
        if (isValueUpdated(modalFieldState.landlordAddressLine1.value)) {
          if (isValueEmpty(modalFieldState.landlordAddressLine1.value)) {
            counterPartyCompanyUpdatePatch.address =
              modalFieldState.landlordAddressLine1.value;
          }
          updatedFields.landlord_seller_info.address =
            modalFieldState.landlordAddressLine1.value;
        }
        if (isValueUpdated(modalFieldState.landlordAddressLine2.value)) {
          if (isValueEmpty(modalFieldState.landlordAddressLine2.value)) {
            counterPartyCompanyUpdatePatch.address2 =
              modalFieldState.landlordAddressLine2.value;
          }
          updatedFields.landlord_seller_info.address2 =
            modalFieldState.landlordAddressLine2.value;
        }
        if (isValueUpdated(modalFieldState.landlordStateName.value)) {
          if (isValueEmpty(modalFieldState.landlordStateName.value)) {
            counterPartyCompanyUpdatePatch.territory =
              modalFieldState.landlordStateName.value;
          }
          updatedFields.landlord_seller_info.state_province =
            modalFieldState.landlordStateName.value;
        }
        if (isValueUpdated(modalFieldState.landlordZip.value)) {
          if (isValueEmpty(modalFieldState.landlordZip.value)) {
            counterPartyCompanyUpdatePatch.postal =
              modalFieldState.landlordZip.value;
          }
          updatedFields.landlord_seller_info.zip =
            modalFieldState.landlordZip.value;
        }

        if (Object.keys(counterPartyCompanyUpdatePatch).length > 0) {
          if (repRole === 'tenant') {
            companiesService.patchCompany(
              counterPartyCompanyId,
              counterPartyCompanyUpdatePatch,
            );
          } else {
            companiesService.patchCompany(
              clientCompanyId,
              counterPartyCompanyUpdatePatch,
            );
          }
        }

        if (isValueUpdated(modalFieldState.landlordPOCPhone.value)) {
          if (isValueEmpty(modalFieldState.landlordPOCPhone.value)) {
            landlordContactUpdatePatch.phone_numbers = [
              {
                type: 'work',
                contact_type: 'landlord',
                number: modalFieldState.landlordPOCPhone.value,
                name: '',
                default: true,
                isValid: true,
              },
            ];
          }
          updatedFields.landlord_seller_info.contact_phone =
            modalFieldState.landlordPOCPhone.value;
        }

        if (isValueUpdated(modalFieldState.landlordPOCEmail.value)) {
          if (isValueEmpty(modalFieldState.landlordPOCEmail.value)) {
            landlordContactUpdatePatch.email_addresses = [
              {
                type: 'work',
                contact_type: 'landlord',
                address: modalFieldState.landlordPOCEmail.value,
                name: '',
                default: true,
                isValid: true,
              },
            ];
          }
          updatedFields.landlord_seller_info.contact_email =
            modalFieldState.landlordPOCEmail.value;
        }

        if (isValueUpdated(modalFieldState.landlordPOCFirstName.value)) {
          if (isValueEmpty(modalFieldState.landlordPOCFirstName.value)) {
            landlordContactUpdatePatch.given_name =
              modalFieldState.landlordPOCFirstName.value;
          }
          updatedFields.landlord_seller_info.contact_first_name =
            modalFieldState.landlordPOCFirstName.value;
        }

        if (isValueUpdated(modalFieldState.landlordPOCLastName.value)) {
          if (isValueEmpty(modalFieldState.landlordPOCLastName.value)) {
            landlordContactUpdatePatch.surname =
              modalFieldState.landlordPOCLastName.value;
          }
          updatedFields.landlord_seller_info.contact_last_name =
            modalFieldState.landlordPOCLastName.value;
        }

        if (isValueUpdated(modalFieldState.landlordPOCJobTitle.value)) {
          if (isValueEmpty(modalFieldState.landlordPOCJobTitle.value)) {
            landlordContactUpdatePatch.job_title =
              modalFieldState.landlordPOCJobTitle.value;
          }
          updatedFields.landlord_seller_info.contact_title =
            modalFieldState.landlordPOCJobTitle.value;
        }

        if (Object.keys(landlordContactUpdatePatch).length > 0) {
          contactsService.patchContact(
            landlordContactId,
            landlordContactUpdatePatch,
          );
        }

        // Conditionally add properties for TenantComapny/Client to companyUpdatePatch
        if (isValueUpdated(modalFieldState.tenantCompanyName.value)) {
          if (isValueEmpty(modalFieldState.tenantCompanyName.value)) {
            clientCompanyUpdatePatch.name =
              modalFieldState.tenantCompanyName.value;
          }
          updatedFields.tenant_buyer_info.org_name =
            modalFieldState.tenantCompanyName.value;
        }

        if (isValueUpdated(modalFieldState.tenantCountryName.value)) {
          if (isValueEmpty(modalFieldState.tenantCountryName.value)) {
            clientCompanyUpdatePatch.country =
              modalFieldState.tenantCountryName.value;
          }
          updatedFields.tenant_buyer_info.country =
            modalFieldState.tenantCountryName.value;
        }

        if (isValueUpdated(modalFieldState.tenantCity.value)) {
          if (isValueEmpty(modalFieldState.tenantCity.value)) {
            clientCompanyUpdatePatch.city = modalFieldState.tenantCity.value;
          }
          updatedFields.tenant_buyer_info.city =
            modalFieldState.tenantCity.value;
        }

        if (isValueUpdated(modalFieldState.tenantAddressLine1.value)) {
          if (isValueEmpty(modalFieldState.tenantAddressLine1.value)) {
            clientCompanyUpdatePatch.address =
              modalFieldState.tenantAddressLine1.value;
          }
          updatedFields.tenant_buyer_info.address =
            modalFieldState.tenantAddressLine1.value;
        }

        if (isValueUpdated(modalFieldState.tenantAddressLine2.value)) {
          if (isValueEmpty(modalFieldState.tenantAddressLine2.value)) {
            clientCompanyUpdatePatch.address2 =
              modalFieldState.tenantAddressLine2.value;
          }
          updatedFields.tenant_buyer_info.address2 =
            modalFieldState.tenantAddressLine2.value;
        }

        if (isValueUpdated(modalFieldState.tenantStateName.value)) {
          if (isValueEmpty(modalFieldState.tenantStateName.value)) {
            clientCompanyUpdatePatch.territory =
              modalFieldState.tenantStateName.value;
          }
          updatedFields.tenant_buyer_info.state_province =
            modalFieldState.tenantStateName.value;
        }

        if (isValueUpdated(modalFieldState.tenantZip.value)) {
          if (isValueEmpty(modalFieldState.tenantZip.value)) {
            clientCompanyUpdatePatch.postal = modalFieldState.tenantZip.value;
          }
          updatedFields.tenant_buyer_info.zip = modalFieldState.tenantZip.value;
        }

        if (Object.keys(clientCompanyUpdatePatch).length > 0) {
          if (repRole === 'tenant') {
            companiesService.patchCompany(
              clientCompanyId,
              clientCompanyUpdatePatch,
            );
          } else {
            companiesService.patchCompany(
              counterPartyCompanyId,
              clientCompanyUpdatePatch,
            );
          }
        }

        if (isValueUpdated(modalFieldState.tenantPOCPhone.value)) {
          if (isValueEmpty(modalFieldState.tenantPOCPhone.value)) {
            tenantContactUpdatePatch.phone_numbers = [
              {
                type: 'work',
                contact_type: 'tenant',
                number: modalFieldState.tenantPOCPhone.value,
                name: '',
                default: true,
                isValid: true,
              },
            ];
          }
          updatedFields.tenant_buyer_info.contact_phone =
            modalFieldState.tenantPOCPhone.value;
        }

        if (isValueUpdated(modalFieldState.tenantPOCEmail.value)) {
          if (isValueEmpty(modalFieldState.tenantPOCEmail.value)) {
            tenantContactUpdatePatch.email_addresses = [
              {
                type: 'work',
                contact_type: 'tenant',
                address: modalFieldState.tenantPOCEmail.value,
                name: '',
                default: true,
                isValid: true,
              },
            ];
          }
          updatedFields.tenant_buyer_info.contact_email =
            modalFieldState.tenantPOCEmail.value;
        }

        if (isValueUpdated(modalFieldState.tenantPOCFirstName.value)) {
          if (isValueEmpty(modalFieldState.tenantPOCFirstName.value)) {
            tenantContactUpdatePatch.given_name =
              modalFieldState.tenantPOCFirstName.value;
          }
          updatedFields.tenant_buyer_info.contact_first_name =
            modalFieldState.tenantPOCFirstName.value;
        }

        if (isValueUpdated(modalFieldState.tenantPOCLastName.value)) {
          if (isValueEmpty(modalFieldState.tenantPOCLastName.value)) {
            tenantContactUpdatePatch.surname =
              modalFieldState.tenantPOCLastName.value;
          }
          updatedFields.tenant_buyer_info.contact_last_name =
            modalFieldState.tenantPOCLastName.value;
        }

        if (isValueUpdated(modalFieldState.tenantPOCJobTitle.value)) {
          if (isValueEmpty(modalFieldState.tenantPOCJobTitle.value)) {
            tenantContactUpdatePatch.job_title =
              modalFieldState.tenantPOCJobTitle.value;
          }
          updatedFields.tenant_buyer_info.contact_title =
            modalFieldState.tenantPOCJobTitle.value;
        }

        if (Object.keys(tenantContactUpdatePatch).length > 0) {
          contactsService.patchContact(
            tenantContactId,
            tenantContactUpdatePatch,
          );
        }
      }

      if (activeTab === 'financials') {
        /*
        If there are no considerations extracted we do not want to update existing voucher data,
        delete existing considerations and create new ones in voucher flow only if
        extracted considerations are not empty
        */
        const isEmpty = isConsiderationSingleAndEmpty(modalFieldState.customConsideration.value);
        if (
          Array.isArray(modalFieldState.customConsideration.value) &&
          !isEmpty
        ) {
          const isAnyElementUpdated =
            modalFieldState.customConsideration.value.some(element =>
              Object.values(element).some(subElement =>
                isValueUpdated(subElement)));
          if (isAnyElementUpdated) {
            const sendCreateRequests = async () => {
              try {
                const response =
                  await considerationsService.fetchConsiderations(deal);
                // Delete existing considerations
                if (response && response.length > 0) {
                  const deleteRequests = response.map(consideration =>
                    considerationsService.deleteConsideration(consideration.id));
                  await Promise.all(deleteRequests);
                }
                /*
                * Using for...of loop with await to maintain strict sequential order of consideration creation.
                * Sequential processing is required to preserve consideration order in downstream systems.
                * Performance impact is negligible since:
                * 1. Max records < 100 in all business scenarios
                * 2. Order preservation outweighs parallel processing benefits
                * 3. Creates are IO bound, not CPU bound means no performance gain from parallel processing
                */
                // Create new considerations sequentially to maintain order
                // eslint-disable-next-line no-restricted-syntax, no-unused-vars
                for (const item of modalFieldState.customConsideration.value) {
                  const considerationsCreatePatch = {
                    consideration_type: item.consideration_type,
                    amount: item.amount,
                    total_units: Number(item.total_units),
                    transaction: deal,
                    consideration_amount: Number(item.consideration_amount),
                    commission_amount: Number(item.commission_amount),
                    commission_percent: Number(item.commission_percent),
                  };
                  // eslint-disable-next-line no-await-in-loop
                  await considerationsService.createConsideration(considerationsCreatePatch);
                }
              } catch (error) {
                console.error('Error in processing considerations:', error);
              }
            };
            // Call the function to send create requests
            sendCreateRequests();
          }
        }
        // update the extracted response with the new considerations if any(for LAE api)
        if (isValueUpdated(modalFieldState.customConsideration.value)) {
          const customConsiderationValue =
            modalFieldState.customConsideration.value.map(({ id, ...values }) => {
              const valuesWithStrings = {};
              Object.keys(values).forEach((key) => {
                valuesWithStrings[key] = String(values[key]);
              });
              return valuesWithStrings;
            });
          if (customConsiderationValue.length > 0) {
            updatedFields.consideration_info.rent_schedule =
              customConsiderationValue;
          }
        }
        if (isValueUpdated(modalFieldState.considerationIsPercent.value)) {
          considerationsUpdatePatch.considerations_is_percent =
            modalFieldState.considerationIsPercent.value === 'True';

          if (Object.keys(considerationsUpdatePatch).length > 0) {
            vouchersService.updateVoucher(voucherId, considerationsUpdatePatch);
          }
        }
      }

      if (activeTab === 'lease-comp') {
        // Conditionally add properties to internalCompUpdatePatch
        if (isValueUpdated(modalFieldState.tenantRepresentCompany.value)) {
          if (isValueEmpty(modalFieldState.tenantRepresentCompany.value)) {
            internalCompUpdatePatch.tenant_represented_by =
              modalFieldState.tenantRepresentCompany.value;
          }
          updatedFields.comp_info.tenant_represented_by =
            modalFieldState.tenantRepresentCompany.value;
        }
        if (isValueUpdated(modalFieldState.tenantRepresentAgent.value)) {
          if (isValueEmpty(modalFieldState.tenantRepresentAgent.value)) {
            internalCompUpdatePatch.tenant_represented_by_agent =
              modalFieldState.tenantRepresentAgent.value;
          }
          updatedFields.comp_info.tenant_represented_by_agent =
            modalFieldState.tenantRepresentAgent.value;
        }
        if (isValueUpdated(modalFieldState.landlordRepresentCompany.value)) {
          if (isValueEmpty(modalFieldState.landlordRepresentCompany.value)) {
            internalCompUpdatePatch.landlord_represented_by =
              modalFieldState.landlordRepresentCompany.value;
          }
          updatedFields.comp_info.landlord_represented_by =
            modalFieldState.landlordRepresentCompany.value;
        }
        if (isValueUpdated(modalFieldState.landlordRepresentAgent.value)) {
          if (isValueEmpty(modalFieldState.landlordRepresentAgent.value)) {
            internalCompUpdatePatch.landlord_represented_by_agent =
              modalFieldState.landlordRepresentAgent.value;
          }
          updatedFields.comp_info.landlord_represented_by_agent =
            modalFieldState.landlordRepresentAgent.value;
        }
        if (isValueUpdated(modalFieldState.baseRent.value)) {
          if (isValueEmpty(modalFieldState.baseRent.value)) {
            internalCompUpdatePatch.base_rent = modalFieldState.baseRent.value;
          }
          updatedFields.comp_info.base_rent = modalFieldState.baseRent.value;
        }
        if (isValueUpdated(modalFieldState.freeRent.value)) {
          if (isValueEmpty(modalFieldState.freeRent.value)) {
            internalCompUpdatePatch.free_rent = modalFieldState.freeRent.value;
          }
          updatedFields.comp_info.free_rent = modalFieldState.freeRent.value;
        }
        if (isValueUpdated(modalFieldState.leaseEscalation.value)) {
          if (modalFieldState.leaseEscalation.value === 'Fixed') {
            internalCompUpdatePatch.rent_escalation_is_custom = 'false';
            updatedFields.comp_info.rent_escalation_is_custom = 'false';
          } else {
            internalCompUpdatePatch.rent_escalation_is_custom = 'true';
            updatedFields.comp_info.rent_escalation_is_custom = 'true';
          }
        }

        if (
          isValueUpdated(modalFieldState.fixedLeaseEscalation.value
            .rent_escalation_is_percent)
        ) {
          internalCompUpdatePatch.rent_escalation_is_percent =
            modalFieldState.fixedLeaseEscalation.value.rent_escalation_is_percent;
          updatedFields.comp_info.rent_escalation_is_percent = String(modalFieldState.fixedLeaseEscalation.value
            .rent_escalation_is_percent);
        }
        if (
          isValueUpdated(modalFieldState.fixedLeaseEscalation.value.rent_escalation_percent)
        ) {
          if (
            isValueEmpty(modalFieldState.fixedLeaseEscalation.value.rent_escalation_percent)
          ) {
            internalCompUpdatePatch.rent_escalation_percent = round(
              modalFieldState.fixedLeaseEscalation.value
                .rent_escalation_percent,
              3,
            );
          }
          updatedFields.comp_info.rent_escalation_percent = round(
            modalFieldState.fixedLeaseEscalation.value.rent_escalation_percent,
            3,
          );
        }
        if (
          isValueUpdated(modalFieldState.fixedLeaseEscalation.value.rent_escalation_dollar)
        ) {
          if (
            isValueEmpty(modalFieldState.fixedLeaseEscalation.value.rent_escalation_dollar)
          ) {
            internalCompUpdatePatch.rent_escalation_dollar =
              modalFieldState.fixedLeaseEscalation.value.rent_escalation_dollar;
          }
          updatedFields.comp_info.rent_escalation_dollar =
            modalFieldState.fixedLeaseEscalation.value.rent_escalation_dollar;
        }
        if (isValueUpdated(modalFieldState.customLeaseEscalation.value)) {
          const customLeaseEscalationValue =
            modalFieldState.customLeaseEscalation.value.map(customLeaseEscalationItem => ({
              rent_dollar: Number(customLeaseEscalationItem.rent.rent_dollar),
              rent_percent: Number(customLeaseEscalationItem.rent.rent_percent),
              is_percent: customLeaseEscalationItem.rent.is_percent,
              months: Number(customLeaseEscalationItem.months),
            }));

          const customLeaseEscalationValueStrings =
            modalFieldState.customLeaseEscalation.value.map(customLeaseEscalationItem => ({
              rent_dollar: String(customLeaseEscalationItem.rent.rent_dollar),
              rent_percent: String(customLeaseEscalationItem.rent.rent_percent),
              is_percent: String(customLeaseEscalationItem.rent.is_percent),
              months: String(customLeaseEscalationItem.months),
            }));

          if (modalFieldState.customLeaseEscalation.value.length > 0) {
            internalCompUpdatePatch.rent_escalation_custom =
              modalFieldState.leaseEscalation.value === 'Custom'
                ? customLeaseEscalationValue
                : [];
            updatedFields.comp_info.rent_escalation_custom =
              modalFieldState.leaseEscalation.value === 'Custom'
                ? customLeaseEscalationValueStrings
                : [];
          }
        }
        if (isValueUpdated(modalFieldState.tenantImprovementAllowance.value)) {
          if (isValueEmpty(modalFieldState.tenantImprovementAllowance.value)) {
            internalCompUpdatePatch.tenant_improvement_allowance_amount_per_sqft =
              modalFieldState.tenantImprovementAllowance.value;
          }
          updatedFields.comp_info.tenant_improvement_allowance_amount_per_sqft =
            modalFieldState.tenantImprovementAllowance.value;
        }
        if (isValueUpdated(modalFieldState.tenantImprovementDetails.value)) {
          if (isValueEmpty(modalFieldState.tenantImprovementDetails.value)) {
            internalCompUpdatePatch.tenant_improvements_detail =
              modalFieldState.tenantImprovementDetails.value;
          }
          updatedFields.comp_info.tenant_improvements_detail =
            modalFieldState.tenantImprovementDetails.value;
        }
        if (isValueUpdated(modalFieldState.parkingRatio.value)) {
          if (isValueEmpty(modalFieldState.parkingRatio.value)) {
            internalCompUpdatePatch.parking_ratio =
              modalFieldState.parkingRatio.value;
          }
          updatedFields.comp_info.parking_ratio =
            modalFieldState.parkingRatio.value;
        }

        // Update the commercial property if commercialPropertyUpdatePatch is having fields other than deal id
        if (Object.keys(internalCompUpdatePatch).length > 0) {
          compDataService.updateCompData(
            internalCompId,
            internalCompUpdatePatch,
          );
        }
      }

      dispatch(updateAttachmentsExtract(fileId, updatedFields));
      return true;
    }
    if (
      errorMessages ||
      !isPropertyTypeValid ||
      !isTenantImprovementDetailsValid ||
      !modalFieldState.isLeaseStartDateValid ||
      !modalFieldState.isLeaseEndDateValid ||
      !modalFieldState.isLeaseExecutionDateValid ||
      !modalFieldState.isLeaseOccupancyDateValid ||
      !modalFieldState.tenantPOCPhoneValid ||
      !modalFieldState.landlordPOCPhoneValid
    ) {
      let fieldError = errorMessages ? `${errorMessages}, ` : '';
      if (!isPropertyTypeValid) {
        fieldError += `Property Type: ${propertyTypeValue}, `;
      }
      if (!isTenantImprovementDetailsValid) {
        fieldError += `Tenant Improvement Details: ${tenantImprovementDetailsValue}, `;
      }
      if (!modalFieldState.isLeaseStartDateValid) {
        fieldError += 'Lease Start Date, ';
      }
      if (!modalFieldState.isLeaseEndDateValid) {
        fieldError += 'Lease End Date, ';
      }
      if (!modalFieldState.isLeaseExecutionDateValid) {
        fieldError += 'Lease Execution Date, ';
      }
      if (!modalFieldState.isLeaseOccupancyDateValid) {
        fieldError += 'Lease Occupancy Date,';
      }
      if (!modalFieldState.tenantPOCPhoneValid) {
        fieldError += 'Tenant POC Phone, ';
      }
      if (!modalFieldState.landlordPOCPhoneValid) {
        fieldError += 'Landlord POC Phone, ';
      }
      dispatch(toast(`Error in field validation: ${fieldError.trim().replace(/,\s*$/, '')}`));
    }
  };

  const handleImportDataAndContinue = () => {
    const updateSuccess = handleUpdateAll();
    if (updateSuccess) {
      setTAndCState({ ...tAndCState, [activeTab]: false });
      setActiveTab(activeTab === 'lease-details' ? 'financials' : 'lease-comp');
    }
  };

  const handleImportDataAndClose = () => {
    const updateSuccess = handleUpdateAll();
    if (updateSuccess) {
      setTAndCState({ ...tAndCState, [activeTab]: false });
      closeDataModal();
    }
  };

  const contextValue = {
    modalFieldState,
    modalFieldChangeHandler,
    individualModalFieldChangeHandler,
    fieldErrorMap,
    validateLeaseAbstractionFields,
    goToPage,
    isMagnified,
  };

  return (
    <LeaseAbstractionContext.Provider value={contextValue}>
      <div
        className={`data-modal ${isMagnified ? 'data-modal--magnified' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="lease-abstraction-modal-header" id="modal-header">
          <div className="header-text">
            Import Data to Voucher - [{dealName}]
          </div>
          <div className="header-buttons">
            <FaRegWindowMinimize
              onClick={hideDataModal}
              className="modal-button-minimize"
            />
            {/* <IconButton
            className="modal-button-close"
            iconName="expand"
            onClick={() => {
              setIsMagnified(!isMagnified);
            }}
            aria-label="expand"
            variant="basic"
          /> */}
            <IconButton
              className="modal-button-close"
              iconName="close"
              onClick={closeDataModal}
              aria-label="close"
              variant="basic"
            />
          </div>
        </div>
        <div
          className="lease-abstraction-modal-content"
          style={{
            height: `calc(100% - ${
              document.getElementById('modal-header')?.offsetHeight
            }px)`,
          }}
        >
          <div
            className={`data-modal-fields ${
              isMagnified ? 'data-modal-fields--magnified' : ''
            }`}
          >
            <div className="data-modal-top">
              <div className="data-modal-padding">
                <div className="instructions-container">
                  <div className="instructions">
                    <div className="instruction">
                      <span className="instruction-number">1.</span>
                      <p
                        className={`instruction-text ${
                          isMagnified ? 'instruction-text--magnified' : ''
                        }`}
                      >
                        Review the extracted data and overwrite fields as needed
                      </p>
                    </div>
                    {showMoreInstructions && (
                      <>
                        <div className="instruction">
                          <span className="instruction-number">2.</span>
                          <p
                            className={`instruction-text ${
                              isMagnified ? 'instruction-text--magnified' : ''
                            }`}
                          >
                            Once you've validated the information, check the box confirming you have validated the data being imported, then select{' '}
                            <span className="instruction-italics">
                              Import Data And Continue
                            </span>{' '}
                          </p>
                        </div>
                        <div className="instruction">
                          <span className="instruction-number">3.</span>
                          <p
                            className={`instruction-text ${
                              isMagnified ? 'instruction-text--magnified' : ''
                            }`}
                          >
                            Repeat the import process on each tab
                          </p>
                        </div>
                        <div className="instruction">
                          <span className="instruction-number"> </span>
                          <p
                            className={`instruction-text ${
                              isMagnified ? 'instruction-text--magnified' : ''
                            }`}
                            style={{ fontStyle: 'italic' }}
                          >
                            *&nbsp;&nbsp;indicates the page number was derived
                            using fuzzy logic and may be &nbsp;&nbsp;inaccurate
                            at times
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="show-details-toggle"

                >
                  <p
                    onClick={() => {
                    setShowMoreInstructions(!showMoreInstructions);
                  }}
                    className="show-details-text"
                  >
                    {showMoreInstructions ? 'Show Less' : 'Show More'}
                  </p>
                </div>
                {results[0]?.data_extract?.type_of_agreement !== 'Lease' && (
                  <div className="amendment-container">
                    <Icon iconName="info-circle" className="amendment-icon" />
                    <p className="amendment-text">
                      We noticed this document looks like an amendment or
                      extension. While we are working on improving our data
                      capture rules for these types of documents results may
                      vary.
                    </p>
                  </div>
                )}
              </div>
              <ul className="navigation-list">
                <li
                  className={`navigation-list-item ${
                    activeTab === 'lease-details'
                      ? 'navigation-list-item--active'
                      : ''
                  }`}
                  onClick={() => {
                    setActiveTab('lease-details');
                  }}
                >
                  Lease Details
                </li>
                <li
                  className={`navigation-list-item ${
                    activeTab === 'financials'
                      ? 'navigation-list-item--active'
                      : ''
                  }`}
                  onClick={() => {
                    setActiveTab('financials');
                  }}
                >
                  Financials
                </li>
                <li
                  className={`navigation-list-item ${
                    activeTab === 'lease-comp'
                      ? 'navigation-list-item--active'
                      : ''
                  }`}
                  onClick={() => {
                    setActiveTab('lease-comp');
                  }}
                >
                  Lease Comp
                </li>
              </ul>
            </div>
            <div className="data-modal-scrollable">
              <div className="navigation-content">
                {activeTab === 'lease-details' && (
                  <LeaseDetails propertyOptions={propertyOptions} />
                )}
                {activeTab === 'lease-comp' && (
                  <>
                    <LeaseComp />
                  </>
                )}
                {activeTab === 'financials' && (
                  <>
                    <Financials />
                  </>
                )}
              </div>
            </div>
            <div className="data-modal-bottom">
              <div className="lease-abstraction-modal-footer">
                {/* <Button
              className="secondary-button"
              variant="secondary"
              onClick={() => {
                trackImportData();
                handleUpdateAll();
              }}
            >
              Import Data
            </Button> */}
                <Checkbox
                  checked={tAndCState[activeTab]}
                  onChange={handleCheckBoxChecked}
                >
                  I have validated the data being imported
                </Checkbox>
                <Button
                  title={`${
                    tAndCState[activeTab]
                      ? ''
                      : 'Please validate the data being imported'
                  }`}
                  disabled={!tAndCState[activeTab]}
                  className="primary-button"
                  variant="primary"
                  onClick={() => {
                    trackImportData();
                    if (['lease-details', 'financials'].includes(activeTab)) {
                      handleImportDataAndContinue();
                    } else {
                      handleImportDataAndClose();
                    }
                  }}
                >
                  {['lease-details', 'financials'].includes(activeTab)
                    ? 'Import Data And Continue'
                    : 'Import Data And Close'}
                </Button>
              </div>
              <div
                className={`disclaimer ${
                  isMagnified ? 'disclaimer--magnified' : ''
                }`}
              >
                <h2 style={{ color: '#3a7f3a', marginBottom: '5px' }}>
                  Disclaimer
                </h2>
                The information contained herein include data that has been
                extracted using artificial intelligence technology. While the
                technology has been tested for accuracy and reliability, the
                data in each field have not been individually verified for
                accuracy or completeness. CBRE, Inc. makes no guarantee,
                representation or warranty and accepts no responsibility or
                liability as to the accuracy, completeness, or reliability of
                the extracted data contained herein. You should conduct a
                careful, independent investigation of all information. Any
                reliance on this information is solely at your own risk.
              </div>
            </div>
          </div>
          <div
            className={`data-modal-file ${
              isMagnified ? 'data-modal-file--magnified' : ''
            }`}
          >
            <div className="data-modal-file-content">
              <iframe
                id="pdf-js-viewer"
                src={pdfViewerUrl}
                title="webviewer"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </LeaseAbstractionContext.Provider>
  );
};

FileModal.propTypes = {
  hideDataModal: PropTypes.func,
  closeDataModal: PropTypes.func,
  results: PropTypes.array,
  deal: PropTypes.number,
  file: PropTypes.object,
  analyticProperties: PropTypes.object,
};

FileModal.defaultProps = {
  hideDataModal: () => {},
  closeDataModal: () => {},
  results: [],
  deal: null,
  file: {},
  analyticProperties: {},
};

export default FileModal;
